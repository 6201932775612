import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/AurumHeroContent.js";
import FeaturesLive from "components/features/LiveThreeColWithTopImage.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
import FAQ from "components/faqs/SingleCol.js";
// import GetStarted from "components/cta/DownloadApp.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import VideoContentImage from "images/aurum_Icons/aurum-video-library.svg";
import ContentSecurityImage from "images/aurum_Icons/aurum-contentSecurity.svg";
import DevicesImage from "images/aurum_Icons/aurum-devicesImage.svg";
import caseStudyLogoImage from "images/clientLogos/igp.png";
import caseStudyProfileImage from "images/lp_anuj_k_acharya.png"
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import DesignIllustration from "images/aurum_Icons/heroImg.svg";
import { components } from "ComponentRenderer.js";
import { Helmet } from 'react-helmet';
import UiUxImage from "images/aurum_Icons/uxcx-engagement.svg";
import agilityImage from "images/aurum_Icons/agilityImage.svg";
import unifiedLibrary from "images/aurum_Icons/unifiedLibrary.svg";
export default ({
  landingPages = components.landingPages,
  innerPages = components.innerPages,
  }) => {
  const Subheading = tw.p`tracking-widest text-textBlack text-center`;
  const HighlightedText = tw.span`text-primary-500`;
  const VerticalSeprator = tw.div`mt-20 xs:mt-0`;

  return (
    <>
      <Helmet
      
      title='Video On Demand Streaming | Recorded Videos'
      
      meta={[
        {
          name: 'description',
          content: 'Video On Demand Streaming, for Large Audiences and Encryption',
        },
        {
          property: `og:title`,
          content: 'Video On Demand Streaming | Recorded Videos',
        },
        {
          property: `og:description`,
          content: 'Video On Demand Streaming, for Large Audiences and Encryption',
        }]}
    />
    
      <Hero roundedHeaderButton={true} 
        mainTitle="Powerful Video on Demand Streaming & Hosting" 
        subTitle="Deliver the optimal, buffer free video experience to Users, No matter where they are"
        buttonText="Lets Connect"
        buttonUrl="https://wa.me/918826622806/?text=Hi, We would like to stream videos with Aurum"
        imageSrc={VideoContentImage}
        showClient= {true}
      />
      <AnimationRevealPage>
        
       
       
        <FeaturesLive
          subheading={<Subheading>Platform that makes Planning, Delivery and Monetisation Easier than Ever</Subheading>}

          heading={
            <>
              Video on Demand Streaming Platform
            </>
          }
          cards={
            [
              { 
                imageSrc: agilityImage, 
                title: "Agile & Secure - Dynamic Use Cases",
                description: "Best Suited for Influencers, Artists and Training & Education" 
              },

              {
                imageSrc: UiUxImage,
                title: "Engaged & Delighted Audience",
                description: "<p>Feeds, Polls, Q&As and more to drive engagement and satisfaction.</p><p> <b></b></p>"
              },
              { imageSrc: unifiedLibrary, 
                title: "The Complete Hassle Free Platform",
                description: "Deliver to 1 or 1 Million, Everything in Built, Easily Scalable." 
              }
            ]
          }
        />
        <MainFeature
          primaryButtonText="Let's Connect"
          primaryButtonUrl = "https://wa.me/918826622806/?text=Hi, We would like to live stream videos with Aurum"
          heading="The Most Powerful and Flexible Video Delivery"
          description="Deliver delightful streaming experience to Audiences of any scale around the globe. Aurum takes care of the entire streaming and distribution process, enabling you to focus only on the Content.<p><b>Host Videos to Stream to Millions</b><br />Aurum is built to scale video streaming across the globe. Deliver best video experiences through global CDN.       Buffer free streaming, continuous playback on almost all devices.</p><p><b>Fully Encrypted Content Files</b><br />Enterprise grade security with dynamic AES encryption. Content creators have full dynamic Digital Rights Management of their assets with Device Lock, View Counts and Blocked Screen Capture in Mobiles and PCs.**</p><p><b>Easy Uploads from Live Sessions and Youtube Videos</b><br />Seamlessly upload, manage and deliver video across channels through secured cloud storage. Ingest videos from Youtube, Vimeo, dropbox or any Other Public URL with ease.</p>"
          subheading="One Platform, End-to-End VOD Solutions"
          textOnLeft={false}
          imageSrc={StrongBrandImage}
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <MainFeature
          primaryButtonText="Let's Connect"
          primaryButtonUrl = "https://wa.me/918826622806/?text=Hi, We would like to live stream videos with Aurum"
          heading="Benefits of Hosting Videos with Aurum"
          description="<p>You get more time to focus on business development and Build Brand Identity. Get More Customisation and Flexibility, Easily Manage Video Access Control and less technical management stress.</p><p><b>Stream Videos with White Labelling</b><br />Embed with Aurum Player, API or deploy ready to use Aurum Platform at a sub domain of your company. Establish your brand identity as per your use case.</p><p><b>Easily Manage User Level Access & DRM</b><br />Define user device, view counts, encryption & validity, modify with ease. Get in full control of digital rights for your assets, monetise efficiently</p><p><b>Expand your Audience with Online / Offline Videos</b><br />Enable users to access videos via internet or in downloaded mode with internet. Use Single File Source for Both formats in mobiles and desktops. Deliver best experiences for your video content.</p>"
          subheading="Your private video library like Youtube"
          imageSrc={VideoContentImage}
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        
        <MainFeature
          primaryButtonText="Let's Connect"
          primaryButtonUrl = "https://wa.me/918826622806/?text=Hi, We would like to live stream videos with Aurum"
          heading="Live Stream on Apps & Devices that Users Love"
          description="Impress Users with availability of the Content in their choice of devices on <b>Aurum Apps</b> or integrate the content from Aurum Content Library to your existing Web & Apps easily with APIs.<p>Deliver Live Sessions on Laptops with Complete Content <b>Security from Downloads and Screen Capture**</b>, enabling much more user engagement and satisfaction in long duration sessions.</p>"
          subheading="Optimise Your User Experience to the Fullest"
          textOnLeft={false}
          imageSrc={DevicesImage}
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <MainFeature
          primaryButtonText="Let's Connect"
          primaryButtonUrl = "https://wa.me/918826622806/?text=Hi, We would like to live stream videos with Aurum"
          heading="Why Aurum is the Best VOD Streaming Solution for Me ??"
          description="<p>Aurum VOD Streaming solutions offers robust infrastructure and auto scaling capabilities, secured and easy integration with Web &amp; Apps for Desktops and Mobiles.</p><p>Choose Aurum if You …<ul><li>Need one stop solution for autoscaling and reach any-size audience.</li><li>Need to ingest videos from Multiple Sources.</li><li>Need Content security in every case.</li><li>Need to deliver content dynamically and flexibly - Online and Offline.</li><li>Need a cloud based solution with pay-as-you go pricing rather than monthly commitments.</li><li>Don’t want to invest huge and manage softwares.</li>"
          subheading=""
          imageSrc={ContentSecurityImage}
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <FAQ
          subheading={<Subheading></Subheading>}
          description={'The Best Platform for Video on Demand Streaming'}
          heading={
            <>
              Frequently Asked Questions
            </>
          }
          faqs={[
            {
              question: "What do we need to start Video Streaming ??",
              answer:"<p>You Only Need to have Your Videos. You Can use Default Aurum Apps, or get your Branded App Developed.<p><br /><p>Simply get your account and video storage bucket created, and start streaming securely.</p>"
            },
            {
              question: "How VOD Streaming Works ?",
              answer:
                "VOD streaming often requires a secured content storage, Video Encryption and Often need multi bitrate encoding, a media server to process the data and convert it when required, a content delivery network to distribute the video across the world smoothly. Further, its usually required to offer controlled access to the videos where monetisation is important.<br /><br />A lot of things happen behind the scenes, but you need not worry about them. Let Aurum enable you to focus only on your business and content."
            },
            {
              question: "How does the Pricing Works ?",
              answer:
                "Aurum Pricing is always Pay as You Go, with all pricing based on GB of data stored and GB of data streamed. 5 Hrs of very good quality video usually forms 1 GB of data. Our pricing is based on usage and volume, and hence the best pricing is offered once we have a complete idea about your use case and volume.<br /><br />contact our sales team for the best quote today."
            },
            {
              question: "Can we Offer Ads in Recorded Videos ?",
              answer:
                "Aurum platforms and player enables Overlay Banner Ads and Video Ads. If you are an Influencer, and seek to monetise, get in touch with us today to explore and execute the best business model for you."
            }
          ]}
        />
        <PreFooter fromPage = "VOD Streaming" formheading = "Live Streaming Increases Amplifies User Engagement and Revenues" formsubheading = "Book Our Free Discussion Session Today - " heightlightText="How to Monetise Videos and Grow Your Business"/>
      </AnimationRevealPage>
      <Footer />
   </>
  );
}
